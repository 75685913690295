import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { connect, MessageOperation } from "../signalR";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { actions } from "./slice";

export const useListConnection = () => {
  const [connection, setConnection] = useState<HubConnection>();
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (connection === undefined) {
      const _connection = connect("/orders/hubs/table/");
      setConnection(_connection);
    }
    if (
      !connection ||
      connection.state === HubConnectionState.Connected ||
      connection.state === HubConnectionState.Connecting
    ) {
      return;
    }
    connection
    .start()
    .then(() => {
      connection.on("Table", (operation: MessageOperation, orderString: string) => {
        dispatch(actions.updateList({ operation, orderString }));
      });
    })
    .catch((e) => {
      console.log("error", e);
    });
    return () => {
      //connection.stop();
    }
  }, [connection?.state, connection, dispatch]);
};

export const useBoardConnection = () => {
  const [connection, setConnection] = useState<HubConnection>();
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (connection === undefined) {
      const _connection = connect("/orders/hubs/board/");
      setConnection(_connection);
    }
    if (
      !connection ||
      connection.state === HubConnectionState.Connected ||
      connection.state === HubConnectionState.Connecting
    ) {
      return;
    }
    connection
    .start()
    .then(() => {
      connection.on("Board", (operation: MessageOperation, orderString: string) => {
        console.log("Board event", )
        dispatch(actions.updateBoard({ operation, orderString }));
      });
    })
    .catch((e) => {
      console.log("error", e);
    });
    return () => {
      //connection.stop();
    }
  }, [connection?.state, connection, dispatch]);
};

export const useOrderConnection = (orderId: string) => {
  const [connection, setConnection] = useState<HubConnection>();
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (connection === undefined) {
      const _connection = connect(`/orders/hubs/${orderId}/`);
      setConnection(_connection);
    }
    if (
      !connection ||
      connection.state === HubConnectionState.Connected ||
      connection.state === HubConnectionState.Connecting
    ) {
      return;
    }
    connection
    .start()
    .then(() => {
      connection.on("Order", (operation: MessageOperation, orderString: string) => {
        dispatch(actions.updateOrder({ operation, orderString }));
      });
    })
    .catch((e) => {
      console.log("error", e);
    });
    return () => {
      //connection.stop();
    }
  }, [connection?.state, connection, dispatch, orderId]);
};
