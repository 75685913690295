import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";
import { axios } from "../axios";

export const connect = (hub = "/hubs/solaris/") => {
  let baseURL = axios.defaults.baseURL;
  baseURL = baseURL?.endsWith("/") ? baseURL.substring(0, baseURL.length - 1) : baseURL;
  const url = `${baseURL}${hub}`;
  return new HubConnectionBuilder()
  .withUrl(url, {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
  })
  .withAutomaticReconnect()
  .build();
}