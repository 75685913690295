import { useTranslation } from "react-i18next";
import { LMNewButton } from "../../../components/LMNewButton";
import { IRolesListItem } from "../types";
import { userNamesTranslations } from "../utils";
import { Body2, H3 } from "../../../components/Typography";
import { IconPlus, IconTimes } from "../../../assets";
import { removeUserFromRole } from "../api";
import styles from "./UserRoleItem.module.css";

interface Props {
  userRole: IRolesListItem;
  onSelectRoleId: (roleId: string | null) => void;
  onSuccess: () => void;
  onError: () => void;
}

export const UserRoleItem = ({
  userRole,
  onSelectRoleId,
  onSuccess,
  onError,
}: Props) => {
  const { t } = useTranslation("userRoles");
  const { t: tUsers } = useTranslation("users");

  const roleKey =
    userNamesTranslations[
      userRole.role.name as keyof typeof userNamesTranslations
    ];

  if (!roleKey) return null;

  const roleText = t(`${roleKey}`, { returnObjects: true }) as {
    title: string;
    description: string;
  };

  const handleAssignModalOpen = () => {
    onSelectRoleId(userRole.role.id);
  };

  const handleRevokeUser = async (userId: string) => {
    try {
      await removeUserFromRole(userId, userRole.role.id);
      onSuccess();
    } catch {
      onError();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles["role-container"]}>
        <H3 className={styles.role}>{roleText.title}</H3>
        <Body2 className={styles.description}>{roleText.description}</Body2>
      </div>
      <div className={styles["users-container"]}>
        <div className={styles.users}>
          {userRole.users.map((user) => (
            <div className={styles.user} key={user.id}>
              <Body2 className={styles["user-name"]}>{user.userName}</Body2>
              <LMNewButton
                color="transparent"
                onClick={() => handleRevokeUser(user.id)}
              >
                {tUsers("revoke")} <IconTimes stroke="var(--color-black)" />
              </LMNewButton>
            </div>
          ))}
        </div>
        <LMNewButton
          className={styles["add-button"]}
          color="transparent"
          onClick={handleAssignModalOpen}
        >
          {tUsers("addUser")} <IconPlus stroke="var(--color-black)" />
        </LMNewButton>
      </div>
    </div>
  );
};
