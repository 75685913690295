import { FC, useCallback, useContext, useEffect, useState } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Body2, Button2 } from "../../../components/Typography";
import { InfoCard } from "../InfoCard";
import { EOrderOperations, IOrder } from "../types";
import { DATE_TIME_NO_SECONDS_FORMAT } from "../../../app/constants";
import { OrderParts } from "../OrderParts";
import { getOrderOperations } from "../api";
import { AbilityContext } from "../../casl";
import styles from "./OrderInfo.module.css";
import { OrderActions } from "../OrderActions";
import { useOrderConnection } from "../hooks";

interface Props {
  order: IOrder;
}

export const OrderInfo: FC<Props> = ({ order }) => {
  const { t } = useTranslation("order");
  const ability = useContext(AbilityContext);
  const [operations, setOperations] = useState<EOrderOperations[]>([]);
  useOrderConnection(order.id);

  const fetchOrderOperations = useCallback(async () => {
    const operations = await getOrderOperations(order.id);
    setOperations(operations.data);
  }, [order]);

  useEffect(() => {
    if (!ability.can("update", "orders")) return;
    fetchOrderOperations();
  }, [fetchOrderOperations, ability]);

  const getDetailsItems = useCallback(() => {
    const items: { name: string | string[]; value: string | number }[] = [
      { name: [t("packs")], value: order.packsCount },
      { name: [t("code")], value: order.code },
      { name: [t("source")], value: order.internal ? t("user") : t("api") },
    ];
    if (!order.details) return items;
    const details = order.details as Record<string, string>;
    Object.keys(details).forEach((detailsKey) => {
      items.push({ name: detailsKey, value: details[detailsKey] });
    });
    return items;
  }, [order, t]);

  return (
    <div className={styles.container}>
      <InfoCard title={t("details")} iconType="orderInfo">
        <div className={styles["details-container"]}>
          {getDetailsItems().map((item) => (
            <div className={styles["details-item"]}>
              <Body2 className={styles["details-title"]}>{item.name}:</Body2>
              <Button2>{item.value}</Button2>
            </div>
          ))}
        </div>
      </InfoCard>
      <InfoCard
        title={`${t("id")}: ${order.id}`}
        status={order.status}
        iconType="order"
        noBottomRadius={!!(order.parts && order.parts.length > 0)}
        action={<OrderActions order={order} operations={operations} />}
      >
        <Body2>
          <span className={styles.light}>
            {format(new Date(order.createdOn), DATE_TIME_NO_SECONDS_FORMAT)}
          </span>
        </Body2>
      </InfoCard>
      {order.parts && <OrderParts parts={order.parts} />}
    </div>
  );
};
