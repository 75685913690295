import { Modal } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LMMultiSelect } from "../../../components/LMMultiSelect";
import { RootState } from "../../../app/store";
import { LMNewButton } from "../../../components/LMNewButton";
import { assignUserToRole } from "../api";
import { IUser } from "../types";
import styles from "./AssignRoleModal.module.css";
import { userNamesTranslations } from "../utils";

interface Props {
  onCancel: () => void;
  onRefresh: () => void;
  user: IUser | null;
  onSuccess: () => void;
  onError: () => void;
}

export const AssignRoleModal: FC<Props> = ({
  onCancel,
  user,
  onRefresh,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation("users");
  const [rolesIds, setRolesIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const roles = useSelector((state: RootState) => {
    return state.users.roles;
  });

  const handleSelectRoles = (value: string[]) => {
    setRolesIds(value);
  };

  const handleAssign = async () => {
    if (!user) return;
    setLoading(true);
    try {
      for (const roleId of rolesIds) {
        await assignUserToRole(user.userId, roleId);
      }
      await setTimeout(() => {
        setLoading(false);
        onSuccess();
        onCancel();
        onRefresh();
      }, 3000);
    } catch {
      setLoading(false);
      onError();
    }
  };

  const getFilteredRoles = useCallback(() => {
    if (!user) return roles;
    return roles.filter((role) => {
      const roleKey =
        userNamesTranslations[
          role.role.name as keyof typeof userNamesTranslations
        ];
      if (!roleKey) return false;
      return !user.roles.find((r) => r.id === role.role.id);
    });
  }, [roles, user]);

  useEffect(() => {
    if (!user) {
      setRolesIds([]);
    }
  }, [user]);

  return (
    <>
      <Modal
        open={user !== null}
        width={400}
        footer={null}
        className={styles.container}
        title={t("assignRole", { name: user ? user.name : "" })}
        onCancel={onCancel}
      >
        <div className={styles.content}>
          <LMMultiSelect
            value={rolesIds}
            onChange={handleSelectRoles}
            placeholder={t("assignUserPlaceholder")}
            items={getFilteredRoles().map((role) => ({
              name: role.role.name,
              value: role.role.id,
            }))}
          />
          <div className={styles.buttons}>
            <LMNewButton
              color="default"
              onClick={onCancel}
              className={styles.button}
            >
              {t("cancel")}
            </LMNewButton>
            <LMNewButton
              onClick={handleAssign}
              disabled={rolesIds.length === 0 || loading}
              className={styles.button}
            >
              {t("add")}
            </LMNewButton>
          </div>
        </div>
      </Modal>
    </>
  );
};
