import { FC, useCallback, useMemo } from "react";
import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IStoreProduct } from "../types";
import { AppDispatch, RootState } from "../../../app/store";
import { LMInputNumberIncremental } from "../../../components/LMInputNumberIncremental";
import { ColumnType } from "antd/es/table";
import { LMTable } from "../../../components/LMTable";
import { actions, fetchList } from "../slice";
import { LMIconButton } from "../../../components/LMIconButton";
import { IconTrash } from "../../../assets";
import { LMNewButton } from "../../../components/LMNewButton";
import { placeOrder } from "../api";
import styles from "./ProductCartDrawer.module.css";

interface Props {
  tenantId: number;
  onClose: () => void;
  open: boolean;
  onSuccess: () => void;
  onError: () => void;
}

export const ProductCartDrawer: FC<Props> = ({
  tenantId,
  onClose,
  open,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation("store");
  const dispatch = useDispatch<AppDispatch>();

  const { list, cartProducts } = useSelector((state: RootState) => {
    return state.store;
  });

  const handleValueChange = useCallback(
    (value: number, storeProduct: IStoreProduct) => {
      let updatedCartProducts = [];
      updatedCartProducts = cartProducts.map((product) => {
        if (product.sku === storeProduct.sku) {
          return {
            ...product,
            quantity: value,
          };
        }
        return product;
      });

      const updatedProducts = list.map((product) => {
        if (product.sku === storeProduct.sku) {
          return {
            ...product,
            quantity: value,
          };
        }
        return product;
      });
      dispatch(actions.updateProducts(updatedProducts));
      dispatch(actions.updateCartProducts(updatedCartProducts));
    },
    [dispatch, cartProducts, list]
  );

  const handleRemove = useCallback(
    (storeProduct: IStoreProduct) => {
      let updatedCartProducts = [];
      updatedCartProducts = cartProducts.filter((product) => {
        return product.sku !== storeProduct.sku;
      });

      const updatedProducts = list.map((product) => {
        if (product.sku === storeProduct.sku) {
          return {
            ...product,
            quantity: 0,
          };
        }
        return product;
      });
      dispatch(actions.updateProducts(updatedProducts));
      dispatch(actions.updateCartProducts(updatedCartProducts));
    },
    [dispatch, cartProducts, list]
  );

  const columns = useMemo(
    () => [
      {
        title: t("name"),
        key: "name",
        dataIndex: "name",
        sorter: true,
      },
      {
        title: t("quantity"),
        key: "quantity",
        render: (product: IStoreProduct) => {
          return (
            <LMInputNumberIncremental
              max={
                product.availableQuantity > 100
                  ? 100
                  : product.availableQuantity
              }
              value={product.quantity}
              onRemove={() => handleRemove(product)}
              name="cart"
              onValueChange={(value: number) =>
                handleValueChange(value, product)
              }
            />
          );
        },
      },
      {
        title: "",
        key: "action",
        render: (product: IStoreProduct) => {
          return (
            <LMIconButton onClick={() => handleRemove(product)}>
              <IconTrash />
            </LMIconButton>
          );
        },
      },
    ],
    [t, handleValueChange, handleRemove]
  ) as ColumnType<IStoreProduct>[];

  const handlePlaceOrder = async () => {
    try {
      await placeOrder(tenantId, cartProducts);
      onSuccess();
      await dispatch(fetchList(tenantId));
      dispatch(actions.updateCartProducts([]));
      onClose();
    } catch {
      onError();
    }
  };

  const handleClear = () => {
    const updatedProducts = list.map((product) => {
      return { ...product, quantity: 0 };
    });
    dispatch(actions.updateProducts(updatedProducts));
    dispatch(actions.updateCartProducts([]));
  };

  return (
    <Drawer title={t("cart")} placement="right" onClose={onClose} open={open}>
      <LMTable
        columns={columns}
        dataSource={cartProducts}
        total={{ title: t("total"), amount: cartProducts.length }}
        rowKey="productId"
      />
      <div className={styles.buttons}>
        <LMNewButton
          color="default"
          onClick={handleClear}
          className={styles.button}
        >
          {t("clear")}
        </LMNewButton>
        <LMNewButton onClick={handlePlaceOrder} className={styles.button}>
          {t("create")}
        </LMNewButton>
      </div>
    </Drawer>
  );
};
